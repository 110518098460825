#classes, #race {
	padding-top: 3rem;
	section {
		margin-bottom: 1.5rem;
		border-bottom: 1px solid #ccc;
		padding-bottom: 1.5rem;
		padding-right: 1.5rem;
		.row-label {
			text-transform: uppercase;
			font-size: 12px;
			color: #909090;
			padding-right: .667rem;
		}
		h1 {
			font-size: 2rem;
			margin-bottom: 0;
		}
		.section-img {
			background-size: contain;
			background-position: center;
			background-repeat: no-repeat;

			&.barb {
				background-image: url(../img/barbarian.jpg);
			}
			&.bard {
				background-image: url(../img/bard.jpg);
			}
			&.cleric {
				background-image: url(../img/cleric.jpg);
			}
			&.druid {
				background-image: url(../img/druid.jpg);
			}
			&.fighter {
				background-image: url(../img/fighter.jpg);
			}
			&.monk {
				background-image: url(../img/monk.jpg);
			}
			&.paladin {
				background-image: url(../img/paladin.jpg);
			}
			&.ranger {
				background-image: url(../img/ranger.jpg);
			}
			&.rogue {
				background-image: url(../img/rogue.jpg);
			}
			&.sorc {
				background-image: url(../img/sorc.jpg);
			}
			&.warlock {
				background-image: url(../img/warlock.jpg);
			}
			&.wizard {
				background-image: url(../img/wizard.jpg);
			}
			// starting race
			&.dragon {
				background-image: url(../img/dragonborn.jpg);
			}
			&.dwarf {
				background-image: url(../img/dwarf.png);
			}
			&.elf {
				background-image: url(../img/elf.jpg);
			}
			&.gnome {
				background-image: url(../img/gnome.png);
			}
			&.half-elf {
				background-image: url(../img/half-elf.png);
			}
			&.half-orc {
				background-image: url(../img/half-orc.jpg);
			}
			&.halfling {
				background-image: url(../img/halfling.jpg);
			}
			&.human {
				background-image: url(../img/human.png);
			}
			&.tiefling {
				background-image: url(../img/tiefling.jpg);
			}


		}
		.desc {
			border-top: 1px solid #909090;
			border-bottom: 1px solid #909090;
			margin-bottom: .5rem;
			color: #909090;
			font-style: italic;
			margin: .333rem 0;
		}
		.primary-abl {
			padding: .333rem 0;
			span {
				&:nth-child(2) {
					font-weight: 500;
				}
				&:last-child {
					color: #606060;
				}
			}
		}
		.list {
			color: #606060;
			text-transform: lowercase;
			font-size: 14px;
		}
		.equip, .prof {
			li:not(:first-child) { 
				padding-left: .667rem;
				.row-label {
					text-decoration: underline;
				}
			}
		}
		.equip {
			ul li:not(:first-child) {
				list-style-type: square;
				padding-left: 0px;
				margin-left: 11px;
			}
			li span {
				color: #cc4b37;
				font-weight: 500;
			}
		}
	}
}
#classes {
	section {
		&:nth-child(odd) {
			padding-right: 0;
			&>div:nth-of-type(2) {
				padding-right: 1.5rem;
				border-right: 1px solid #ccc;
			}
		}
	}
}
#race {
	max-width: 1200px;
	margin: auto;
	section	{
		padding-right: 0;
		&>div {
			padding-left: 2rem;
		}
		.traits {
			li {
				text-transform: capitalize;
			}
		}
	}
	.class-aff { display: none; }
}